export interface IStat {
    key: string
    value: number
}

export interface StatUserPayload {
    user_genders: IStat[]
    user_ages: IStat[]
    user_levels: IStat[]
    user_strokes: IStat[]
    user_countries: IStat[]
    user_cities: IStat[]
}

export class StatUser {
    userGenders: IStat[]
    userAges: IStat[]
    userLevels: IStat[]
    userStrokes: IStat[]
    userCountries: IStat[]
    userCities: IStat[]

    constructor(data: StatUserPayload) {
        this.userGenders = data.user_genders
        this.userAges = data.user_ages
        this.userLevels = data.user_levels
        this.userStrokes = data.user_strokes
        this.userCountries = data.user_countries
        this.userCities = data.user_cities
    }

    fill(data: StatUserPayload): this {
        this.userGenders = data.user_genders
        this.userAges = data.user_ages
        this.userLevels = data.user_levels
        this.userStrokes = data.user_strokes
        this.userCountries = data.user_countries
        this.userCities = data.user_cities
        return this
    }

    unset(): this {
        this.fill(<any>{})
        return this
    }
}
