export interface IStatPeriod {
    day: number
    week: number
    month: number
    year: number
}

export interface StatPayload {
    registrations: IStatPeriod
    workout_executions: IStatPeriod
    program_executions: IStatPeriod
}

export class Stat {

    registrations: IStatPeriod = { day: 0, week: 0, month: 0, year: 0 }
    workoutExecutions: IStatPeriod = { day: 0, week: 0, month: 0, year: 0 }
    programExecutions: IStatPeriod = { day: 0, week: 0, month: 0, year: 0 }

    constructor(data: StatPayload) {
        this.registrations = data.registrations
        this.workoutExecutions = data.workout_executions
        this.programExecutions = data.program_executions
    }

    fill(data: StatPayload): this {
        this.registrations = data.registrations
        this.workoutExecutions = data.workout_executions
        this.programExecutions = data.program_executions
        return this
    }

    unset(): this {
        this.fill(<any>{})
        return this
    }
}
